

.loading {
	filter: blur(10px);
	clip-path: inset(0);
}

.loaded {
	filter: blur(0px);
	transition: filter 0.5s linear;
}
